<template>
  <b-col md="12">
    <b-overlay
      :show="commandLoading"
      rounded="sm"
    >
      <b-card>
        <b-row>
          <b-col
            md="3"
          >
            <b-button
              variant="outline-primary btn-block"
              size="md"
              @click="processEventAction"
            >
              Process Events
            </b-button>
          </b-col>
          <b-col
            md="3"
            class="mt-1 mt-md-0"
          >
            <b-button
              variant="outline-primary btn-block"
              size="md"

              @click="reportDailyAction"
            >
              Report daily
            </b-button>
          </b-col>
          <b-col
            md="3"
            class="mt-1 mt-md-0"
          >
            <b-button
              variant="outline-primary btn-block"
              size="md"
              @click="shopeeVouchersAction"
            >
              Get Shopee Vouchers
            </b-button>
          </b-col>
          <b-col
            md="3"
            class="mt-1 mt-md-0"
          >
            <b-button
              variant="outline-primary btn-block"
              size="md"
              @click="resetDataAction"
            >
              Reset data
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </b-col>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toastification } from '@core/mixins/toast'
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'

const { mapGetters, mapActions } = createNamespacedHelpers('dashboard')

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  mixins: [
    toastification,
  ],
  data() {
    return {
      commandLoading: false,
      reports: [
        { name: 'Solar day', value: 'solar_day', icon: 'InboxIcon' },
        { name: 'Lunar day', value: 'lunar_day', icon: 'InboxIcon' },
        { name: 'Fasting day', value: 'fasting_day', icon: 'InboxIcon' },
        { name: 'Pay day', value: 'pay_day', icon: 'InboxIcon' },
        { name: 'Love days', value: 'love_day', icon: 'InboxIcon' },
        { name: 'Tam red day', value: 'tam_red_day', icon: 'InboxIcon' },
      ],
    }
  },
  computed: {
    ...mapGetters(['message', 'status', 'loading', 'dashboard']),
  },

  created() {
    this.getDashboard()
  },

  methods: {
    ...mapActions([
      'getDashboard',
      'reportDaily',
      'processEvent',
      'shopeeVouchers',
      'resetData',
    ]),
    getLodash(value) {
      return _get(this.dashboard, [value], 0).toLocaleString('en-US')
    },
    async reportDailyAction() {
      this.commandLoading = true
      await this.reportDaily()
      if (this.status) {
        this.toastSuccess('Report daily successfully')
      } else {
        this.toastFailure(this.message)
      }
      this.commandLoading = false
    },
    async processEventAction() {
      this.commandLoading = true
      await this.processEvent()
      if (this.status) {
        this.toastSuccess('Process event successfully')
      } else {
        this.toastFailure(this.message)
      }
      this.commandLoading = false
    },
    async shopeeVouchersAction() {
      this.commandLoading = true
      await this.shopeeVouchers()
      if (this.status) {
        this.toastSuccess('Get shopee voucher successfully')
      } else {
        this.toastFailure(this.message)
      }
      this.commandLoading = false
    },
    async resetDataAction() {
      this.commandLoading = true
      await this.resetData()
      if (this.status) {
        this.toastSuccess('Reset data successfully')
      } else {
        this.toastFailure(this.message)
      }
      this.commandLoading = false
    },
  },
}
</script>
