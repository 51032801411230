<template>
  <b-row>
    <DashboardCommands />

    <b-col
      v-for="(report, index) in reports"
      :key="index"
      md="3"
    >
      <b-card>
        <div class="d-flex align-items-center">
          <div>
            <b-spinner
              v-if="loading"
              variant="primary"
              small
            />
            <h4
              v-else
              class="font-weight-bold"
            >
              {{ report.name }}
            </h4>
            <div>{{ getLodash(report.value) }}</div>
          </div>
          <div v-if="false">
            <div class="icon d-flex align-items-center justify-content-center">
              <feather-icon
                class="text-primary"
                icon="ZapIcon"
                size="24"
              />
            </div>
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'
import DashboardCommands from './components/DashboardCommands.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('dashboard')

export default {
  components: {
    DashboardCommands,
  },
  data() {
    return {
      reports: [
        { name: 'Solar day', value: 'solar_day', icon: 'InboxIcon' },
        { name: 'Lunar day', value: 'lunar_day', icon: 'InboxIcon' },
        { name: 'Fasting day', value: 'fasting_day', icon: 'InboxIcon' },
        { name: 'Pay day', value: 'pay_day', icon: 'InboxIcon' },
        { name: 'Love days', value: 'love_day', icon: 'InboxIcon' },
        { name: 'Tam red day', value: 'tam_red_day', icon: 'InboxIcon' },
      ],
    }
  },
  computed: {
    ...mapGetters(['message', 'status', 'loading', 'dashboard']),
  },

  created() {
    this.getDashboard()
  },

  methods: {
    ...mapActions(['getDashboard']),
    getLodash(value) {
      return _get(this.dashboard, [value], 0).toLocaleString('en-US')
    },
  },
}
</script>
